
import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Product } from '../../models/Product';
import { MasterGroupService } from '../../_services/mastergroup.service';
import { MasterGroup } from '../../models/MasterGroup';
import { AppUser } from '../../models/AppUser';
import { CookieService } from 'ngx-cookie-service';
import { MatSnackBar,MatSnackBarConfig} from '@angular/material';
import { ProductService } from '../../_services/product.service';
@Component({
  selector: 'app-createproduct-confirmation',
  templateUrl: './createproduct-confirm.component.html',
  styleUrls: ['./createproduct-confirm.component.css']
})
export class CreateProductConfirmationComponent implements OnInit {
  curModel:Product=new Product();
  group:MasterGroup[];
  appUser:AppUser;
  myrate:number;
  constructor(public dialogRef: MatDialogRef<CreateProductConfirmationComponent>,
    private masterGroupService:MasterGroupService,private cookieService: CookieService,
    public snackBar: MatSnackBar,private productService:ProductService) { 
      this.appUser = JSON.parse(this.cookieService.get('barcodeuser'));
    }

  ngOnInit() {
    this.loadGroup();
   console.log(this.appUser);
  }
  public loadGroup():void
  {
    this.masterGroupService.GetMasterGroups(this.appUser.id).subscribe(result=>{
       this.group=result;
       console.log(this.group);
    });
  }
  public onOK():void
  {
    let config = new MatSnackBarConfig();
    config.verticalPosition = 'top';
    config.horizontalPosition = 'right';
    config.duration = 3000;
    if(this.curModel.groupName==null || this.curModel.groupName=="")
    {
      config.panelClass=['accent-color'];
      this.snackBar.open("Group selection is required",null,config);
      return;
    } 
    else if(this.curModel.name==null || this.curModel.name=="")
    {
      config.panelClass=['accent-color'];
      this.snackBar.open("Product  name is required",null,config);
      return;
    }
    else if(this.curModel.name.length>12)
    {
      config.panelClass=['accent-color'];
      this.snackBar.open("Please enter product name less then 12 charaters",null,config);
      return;
    }
    else if(this.curModel.printName==null || this.curModel.printName=="")
    {
      config.panelClass=['accent-color'];
      this.snackBar.open("Print  name is required",null,config);
      return;
    }
    else if(this.curModel.printName.length>12)
    {
      config.panelClass=['accent-color'];
      this.snackBar.open("Please enter print name less then 12 charaters",null,config);
      return;
    }
    else if(this.curModel.GSTPER==null)
    {
      config.panelClass=['accent-color'];
      this.snackBar.open("GST selection is required",null,config);
      return;
    }
    else if(this.curModel.hsnCode==null || this.curModel.hsnCode=="")
    {
      config.panelClass=['accent-color'];
      this.snackBar.open("HSN Code is required",null,config);
      return;
    }
    
    else if(this.curModel.purchasePrice==null)
    {
      config.panelClass=['accent-color'];
      this.snackBar.open("Purchase is required",null,config);
      return;
    }
    else if(this.curModel.salePrice==null)
    {
      config.panelClass=['accent-color'];
      this.snackBar.open("Sale price is required",null,config);
      return;
    }
    this.productService.CreateProduct(this.curModel).subscribe(result=>{
     
      if(result)
      {
        this.snackBar.open("Product successfully created",null,config);
        this.dialogRef.close(true);
      }
      else
      {
        this.snackBar.open("This product name is alreday created, Please select another product name",null,config);
      }
     
    });
    
  }
  public  onCancel():void
  {
    this.dialogRef.close(false);
  }
  getMrp(purchase: number, gst: number, dhara: number,discper:number) {  
    let config = new MatSnackBarConfig();
    config.verticalPosition = 'top';
    config.horizontalPosition = 'right';
    config.duration = 3000;  
     if (purchase == undefined) return;
     if (gst == undefined)
     {
      this.snackBar.open("Please choose GST % First.",null,config);
      return;
     }
     var discamt=purchase-((purchase*discper)/100);
     var mrp = discamt + ((discamt / (100 - dhara)) * dhara);
  //   mrp += (mrp * gst / 100); gst

     var rem = Math.round(mrp);            
     mrp =  rem;       
var reminder = mrp % 10;
mrp = mrp;
    
     if (reminder == 0) {
         //return ($scope.tempProduct.ProdID == 3) ? mrp + $scope.stitchCharge.Charge : mrp;
     }
     if (reminder <= 2) {
         mrp -= reminder;
     }
     else if (reminder <= 7 && reminder > 5) {
         mrp -= (reminder - 5);
     }
     else if (reminder >= 3 && reminder < 5) {
         mrp += (5 - reminder);
     }
     else if (reminder >= 8 && reminder <= 10) {
         mrp += (10 - reminder);
     }     
     this.curModel.salePrice = mrp; 
     this.myrate=mrp;
     return mrp;

 }
}
